import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "../assets/scss/style.scss";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div id="not-found-page" className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>{t("notFound.oops")}</h1>
              <h2>{t("notFound.title")}</h2>
              <div className="error-details">
                {t("notFound.message")}
              </div>
              <div className="error-actions">
                <a href="/" className="btn btn-primary btn-lg">
                  <span className="glyphicon glyphicon-home"></span>
                  {t("notFound.takeMeHome")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
